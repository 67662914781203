































































































































































































































































































































































































































.el-tooltip__popper {
  max-width: 40% !important;
}
.tablequestionTitle {
  img {
    width: 3rem;
  }
}
