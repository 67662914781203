.el-form-questionType {
  display: flex;
}
.el-form-item__content {
  display: flex;
  align-items: center;
}
.el-form-item__content .el-radio-group .el-form-item /deep/ .el-form-item__content {
  display: flex;
  align-items: center;
}
.el-form-item__content .checkbox /deep/ .el-form-item__content {
  display: flex;
  align-items: center;
}
.el-form-item__content .checkbox /deep/ .el-form-item__content .el-checkbox {
  display: flex;
  align-items: center;
}
.el-form-item__content .checkbox /deep/ .el-form-item__content .el-checkbox .el-checkbox__label {
  display: flex;
  align-items: center;
}
.el-form-item__content .checkbox /deep/ .el-form-item__content .el-checkbox .el-checkbox__label .el-input {
  margin-left: 1.5rem;
}
.delet {
  margin-left: 0.5rem;
}
.el-form-item__content .img-el-upload /deep/ .el-upload {
  margin-left: 15px;
  width: 30px;
  height: 30px !important;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
